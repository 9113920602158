
.flippy-container *
{
    box-sizing: border-box;
}

.flippy-cardContainer-wrapper
{
	position: relative;
	height: 100%;
    width: 100%;
}

.flippy-cardContainer
{
    height: 100%;
    width: 100%;
    position: relative;

    -webkit-transition: .6s;
       -moz-transition: .6s;
        -ms-transition: .6s;
         -o-transition: .6s;
            transition: .6s;
    -moz-transform: perspective(1000px);
         transform: perspective(1000px);

    -webkit-transform-style: preserve-3d;
       -moz-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;
         -o-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

.flippy-front,
.flippy-back
{
    position: relative;

    width: 100%;
    height: 100%;
    padding: 1em;

    -webkit-transform: rotateY(0deg);
       -moz-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
         -o-transform: rotateY(0deg);
            transform: rotateY(0deg);

    background: transparent;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);

    -webkit-backface-visibility: hidden;
       -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
       -moz-transform-style: preserve-3d;
         -o-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

.flippy-front
{
    -webkit-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
            transform: rotateY(0deg);
}

.flippy-back
{
    position: absolute;
    top: 0;
    left: 0;

    -webkit-transform: rotateY(-180deg);
       -moz-transform: rotateY(-180deg);
        -ms-transform: rotateY(-180deg);
         -o-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
}

.flippy-cardContainer.isActive:not(.istouchdevice) .flippy-back,
.flippy-cardContainer.isActive.istouchdevice .flippy-back
{
    -webkit-transform: rotateY(0deg);
       -moz-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
         -o-transform: rotateY(0deg);
            transform: rotateY(0deg);
}

.flippy-cardContainer.isActive:not(.istouchdevice) .flippy-front,
.flippy-cardContainer.isActive.istouchdevice .flippy-front
{
    -webkit-transform: rotateY(180deg);
       -moz-transform: rotateY(180deg);
        -ms-transform: rotateY(180deg);
         -o-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.flippy-cardContainer-wrapper.vertical .flippy-back
{
    -webkit-transform: rotateX(-180deg);
       -moz-transform: rotateX(-180deg);
        -ms-transform: rotateX(-180deg);
         -o-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
}

.flippy-cardContainer-wrapper.vertical .flippy-cardContainer.isActive:not(.istouchdevice) .flippy-back,
.flippy-cardContainer-wrapper.vertical .flippy-cardContainer.isActive.istouchdevice .flippy-back
{
    -webkit-transform: rotateX(0deg);
       -moz-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
         -o-transform: rotateX(0deg);
            transform: rotateX(0deg);
}

.flippy-cardContainer-wrapper.vertical .flippy-cardContainer.isActive:not(.istouchdevice) .flippy-front,
.flippy-cardContainer-wrapper.vertical .flippy-cardContainer.isActive.istouchdevice .flippy-front
{
    -webkit-transform: rotateX(180deg);
       -moz-transform: rotateX(180deg);
         -o-transform: rotateX(180deg);
            transform: rotateX(180deg);
            transform: rotateX(180deg);
}
